@font-face {
  font-family: 'Glyphs';
  /* Remplacez 'MaPolice' par le nom que vous souhaitez donner à votre police. */
  src: url('./assets/font/stargate_sg1_adress_glyphs.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.glyphs {
  font-family: 'Glyphs', sans-serif !important;
  font-size: 2rem;
}